import { EAttachementType } from '../enums';
import { IUser } from '../interfaces';
import { TProject } from './project.type';
import { TTask } from './task.type';
import { TWorkspace } from './workspace.type';

export type TAttachement = {
  id: string;
  number: number;
  uri: string;
  contentType: string;
  size: number;
  type: EAttachementType | string;
  folder: TAttachementsFolder | string;
  workspace?: TWorkspace | string;
  task?: TTask | string;
  project: TProject | string;
  isAttachedToReport: boolean;
  isValidateByManager: boolean;
  user: IUser | string;
  createdBy: string;
  remarkToColaborator: string;
  crNumber: string;
  name:string;
  // displaySize: string;
};

export type TAttachementsFolder = {
  name: string;
  createdAt: string;
};
