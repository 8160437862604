export type TTodo = {
  id: string;
  uuid: string;
  title: string;
  isDone: boolean;
  content: string;
  reminder?:string;
  createdAt?: string;
  updatedAt?:string;
  checklist: TChecklistItem[];
};

export type TChecklistItem = {
  description: string;
  checked: boolean;
};

export type TCreateTodo = {
  title: string;
  isDone: boolean;
  content: string;
  checklist: {
    description: string;
    checked: boolean;
  }[];
};

export type TUpdateTodo = {
  title?: string;
  isDone?: boolean;
  content?: string;
  checklist?: {
    description: string;
    checked: boolean;
  }[];
};
