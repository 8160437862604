// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.

export const environment = {
  production: false,
  api_host: 'https://api.coordate.com/',
  showReactQueryDevtools: true,
  firebase: {
    apiKey: 'AIzaSyD3XkOByE0IcaW2Zzzdv_WZu2CJcPePbEw',
    authDomain: 'coordate-63c61.firebaseapp.com',
    projectId: 'coordate-63c61',
    storageBucket: 'coordate-63c61.appspot.com',
    messagingSenderId: '262872777103',
    appId: '1:262872777103:web:8becac52384b1159a28bfa',
    measurementId: 'G-NWH8T9VE5P',
  },
};
