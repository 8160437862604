export interface IAttachment {
    commentCount: string;
    uuid: string;
    name: string;
    uri: string;
    size: number;
    version: number;
    displaySize: string;
    type: string;
    validations: Array<{
      attachment: string;
      workspaceUuid: string;
      status: string;
      updatedBy: {
        uuid: string;
        fullName: string;
        imageUri: string;
      };
      createdAt: string;
      updatedAt: string;
    }>;
    createdAt: string;
    updatedAt: string;
    uploadedByWorkspace: {
      uuid: string;
      name: string;
      color: string;
      projectUuid: string;
      collaborators: Array<{
        uuid: string;
        fullName: string;
        imageUri: string;
        phone: string;
        company: {
          uuid: string;
          name: string;
          email: string;
          phone: string;
          address: string;
          imageUri: string;
          webSite: string;
        };
      }>;
      type: string;
      taskCount: number;
      createdAt: string;
      updatedAt: string;
    };
  }